.container {
    padding: 20px 2px 2px;
    position: relative;
    overflow: hidden;
  }
  
  .header {
    display: flex;
    height: 50px;
    justify-content: space-around;
    width: 100vw;
    background: white;
    color: #06F;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .list {
    flex: 1;
  }
  
  .contactCard {
    background-color: #0066ff1a;
    border: .5px solid #07036321;
    /* height: 55px; */
    padding: 14px 50vw 13px 27.7px;
    position: relative;
    width: 100vw;
    transition: transform 0.3s ease;
  }
  
  .contactName {
    color: #070363;
    font-size: 16px;
    font-weight: 600;
    width: 80vw;
    font-family: sans-serif;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .contactName-and-flag {
    display:inline-flex;
    justify-content: space-between;
  }

  .contactEmail {
    color: #070363;
    font-family: sans-serif;
    margin: 0;
  }
  
  .addContact {
    position: absolute;
    bottom: 15px;
    right: 25px;
  }

  .home-navbar {
    display: inline-flex;
    padding-top: 10px;
    width: 100vw;
    bottom: auto;
    justify-content: space-around;
    padding-bottom: 10px;
    background: white;
    color: #06F;
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
  

  .side-swipe-menu {
    background-color: #fff;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
  }

  .side-swipe-menu button {
    padding: 10px;
    border: none;
    cursor: pointer;
  }

  .note {
    background: #0066ff;
    color: white;
    width: 65px;
  }

  .delete {
    background: #f75050;
    color: white;
    width: 70px;
  }

  .flag {
    background: #0099ff;
    color: white;
    width: 65px;
  }

  /* .contactInfo {
    position: absolute;
  } */

  .modalContent {
    background-color: #e5f0ff;
    border-radius: 10px;
    box-shadow: 0 0 5px #00000080;
    padding: 20px;
  }
  
  .modalButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .input {
    border-color: gray;
    border-width: 1px;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  .contactList {
    left: 0;
    margin-bottom: 13vh;
    margin-top: 13vh;
    position: sticky;
}
 

  .login-container {
    background-image: url('../assets/Bg@3x.png');
    background-size: contain;
    height: 90vh;
    height: 100vh;
  }
  
  .Principal-cta {
    width: 170px;
    height: 54px;
    position: absolute;
    top: 64vh; 
    left: 50%; 
    transform: translateX(-50%);   
    object-fit: contain;
    color: white;
    background-color: #1d196b;
    border-radius: 30px;
    font-size: larger;
    font-style: normal;
    box-shadow: 0 2.8px 2.2px 0 rgba(147, 120, 255, 0.02), 0 6.7px 5.3px 0 rgba(147, 120, 255, 0.03), 0 12.5px 10px 0 rgba(147, 120, 255, 0.04), 0 22.3px 17.9px 0 rgba(147, 120, 255, 0.04), 0 41.8px 33.4px 0 rgba(147, 120, 255, 0.05), 0 100px 80px 0 rgba(147, 120, 255, 0.07);
  }
 

  .Welcome-to-Sellercloud-User-Conference {
    width: 86vw;
    height: 50px;
    position: absolute;
    top: 50vh; 
    left: 50%; 
    transform: translateX(-50%);   
    font-family: 'Galano Grotesque', sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #06f;
  }

  .Dont-have-an-account {
    width: 60vw;
    height: 54px;
    position: absolute;
    top: 76vh; 
    left: 50%; 
    transform: translateX(-50%);   
    font-family: 'Galano Grotesque', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #070363;
  }

  .text-style-1 {
    color: #070363;
    text-decoration: none;
    display: block;
  }

  a.text-style-1 {
    font-weight: bold;
  }

  img.sc-connect-logo {
    width: 66vw;
    position: absolute;
    top: 11vh; 
    left: 50%; 
    transform: translateX(-50%);
    max-width: 350px;   
  }

  .login-qrcode-reader {
    top: 18vh;
    left: 0px;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
  }

  .email-confirmation-dialog {
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    margin: 0 auto;
    max-width: 400px;
    padding: 20px;
  }

  .email-dialog-header{
    font-size: 1.5rem;
    text-align: center;
  }

  .dialog-input{
    width: 100%;
    margin-bottom: 20px;
  }

  .email-dialog-buttons {
    display: inline-flex;
    justify-content: space-between;
    margin: 5px;
}

  .email-dialog-ok{
    color:#0066ff
  }
  
  .email-dialog-cancel{
    color: #f75050;
  }

  .css-1ujsas3 {
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
}

.css-1v4ccyo {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.css-1ald77x {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(18px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  user-select: none;
}

.css-uhb5lp {
  background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    margin: 32px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 600px;
}

.homepage-title {
  font-size: 25px;
  margin-top: 10px;
}

.add-contact-button {
  margin-bottom: -10px;
  padding: 5px;
  border: #06f;
  background: white;
  height: 50px;
}

.export-to-excel {
  padding: 5px;
  border: none;
  background: white;
}

.logout-button {
  background: #fff;
  border-color: #06f;
  border-radius: 20px;
  color: #06f;
  margin: 5px;
}

.user-Profile {
  display: inline-flex;
}


.username {
  font-size: x-small;
}


.header-message {
  font-size: small;
  color: #414141;
  text-align: center;
}

.start-adding-contacts {
  text-align: center;
  position: absolute;
  top: 10vh;
  left: 25vw;
  color: #414141;
}